import { Route } from 'react-router';
import { AppRoute } from '@app/routesEnum';
import { SiteSection } from '@shared/components/analytics/site-section.component';
import { LazyDriverRoleInformation } from './index';

export default function DriverRoleInformationRoutes() {
 return (
  <SiteSection name='Driver Role Information'>
   <Route path={AppRoute.DRIVER_ROLE_INFORMATION} exact component={LazyDriverRoleInformation} />
  </SiteSection>
 );
}